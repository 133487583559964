import React, { useState, useContext } from "react";

import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  DialogContentText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@material-ui/core";
import { SendQuoteContext } from "../../../../../context/states/sendQuoteState";

import Slide from "@material-ui/core/Slide";

const styles = {
  colOne: {
    width: "40%",
  },
  colTwo: {
    width: "20%",
  },
  colThree: {
    width: "20%",
  },
  colFour: {
    width: "20%",
  },
  colTranOne: { width: "20%" },
  colTranTwo: { width: "20%" },
  colTranThree: { width: "20%" },
  colTranFour: { width: "20%" },
  colTranFive: { width: "20%" },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    margin: theme.spacing(1, 0),
  },
  tableTopBorder: {
    borderWidth: 0,
    borderTopWidth: 0.5,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    fontSize: "13px",
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
    fontSize: "13px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SendQuote = React.memo((props) => {
  const classes = useStyles();
  const { open, handleClose, onSubmitHandler } = props;
  const [isHide, setIsHide] = useState(false);
  const { loading, isQuote, iload, profileImage, mailHandler } =
    useContext(SendQuoteContext);
  const { booking, term } = isQuote;
  const [isSendBtn, setIsSendBtn] = useState(false);

  // if (iload) {
  //   console.log(profileImage);
  // }

  const confirmHandler = async () => {
    console.log("confirm clicked");
    await onMailSubmitHandler();
    await closeHandler();
  };

  const closeHandler = () => {
    handleClose();
    setIsHide(false);
  };

  let invoiceContent = "";
  let quotationContent;
  let mailContainer;
  let interpreterContent;

  const sT = booking?.serviceType;
  const status =
    booking?.status !== "Quotation" ? "Booking Confirmation" : "Quotation";

  const mailSubject = `Ref: ${booking?.fileRef} / ${
    booking?.serviceType === "Telephone" ||
    booking?.serviceType === "Video Call" ||
    booking?.serviceType === "Onsite"
      ? booking?.serviceType + " Interpretation"
      : booking?.serviceType
  }  / ${booking?.language} / ${booking?.booking_ref}`;

  const serviceLabel = booking?.vat ? "VAT" : "Service Charges";
  const onMailSubmitHandler = async () => {
    console.log("mail sending....");
    const data = { _id: booking?._id };
    if (booking?.status !== "Quotation") {
      data.status = "Confirmed";
      data.dateOfConfirmation = new Date();
      // data.isBooked = true;
    } else {
      data.status = "Quote Supplied";
    }

    const customerMailData = {
      to:
        booking?.invoiceTo !== booking?.email
          ? [{ email: booking?.email }, { email: booking?.invoiceTo }]
          : [{ email: booking?.email }],
      cc: process.env.REACT_APP_BOOKING_EMAIL,
      from: {
        email: process.env.REACT_APP_BOOKING_EMAIL,
        name: process.env.REACT_APP_BOOKING_EMAIL_SENDER_NAME,
      },
      subject: `${status} | ${mailSubject}`,
      content: [{ type: "text/html", value: mailContainer }],
      filename:
        booking?.serviceType === "Onsite" && booking?.status === "Booking"
          ? booking?.interpreterDetails.photo
          : undefined,
    };

    await setIsSendBtn(true);
    try {
      await mailHandler(customerMailData);
      await onSubmitHandler(data);
      await setIsSendBtn(false);
    } catch (err) {
      console.error(err);
      await setIsSendBtn(false);
    }

    // Interpreter mailing
    if (booking?.status !== "Quotation") {
      const interpreterConfirmData = {
        // to: [{ email: 'athar@versamicro.com' }, { email: 'sajid@versamicro.com' }],
        to: [{ email: booking?.interpreterDetails.email }],
        cc: process.env.REACT_APP_BOOKING_EMAIL,
        from: {
          email: process.env.REACT_APP_BOOKING_EMAIL,
          name: process.env.REACT_APP_BOOKING_EMAIL_SENDER_NAME,
        },
        subject: `Interpreter - ${status} | ${mailSubject}`,
        content: [{ type: "text/html", value: interpreterContent }],
      };

      try {
        await mailHandler(interpreterConfirmData);
        await setIsSendBtn(false);
      } catch (err) {
        console.error(err);
        await setIsSendBtn(false);
      }
    }
  };

  let isDuration = "";
  var x = moment(booking !== undefined ? booking.startTime : 0);
  var y = moment(booking !== undefined ? booking.endTime : 0);
  const hours = moment.duration(y.diff(x)).add(1, "seconds");
  const { _data } = hours;

  isDuration = _data.hours + "hr " + _data.minutes + "min";

  let totalInvoiceAmount =
    booking?.quotation?.totalPercentage + booking?.quotation?.subTotalAmount ||
    0;
  let totalDueAmount = totalInvoiceAmount - booking?.advancePayment || 0;

  const footerSignature = `
  <div style="padding: 12px 16px;">
    <p>Kind regards</p>
    <b>Language Interpreters</b>
    <p>
      T- <a href="tel:020 8123 5556">020 8123 5556</a>
      <br />
      E- <a href="mailto:020 8123 5556">info@language-interpreters.com</a>
      <br />
      Website- <a href="https://language-interpreters.com/" target="_blank">www.language-interpreters.com </a>
      <br />
      A – 124 City Road , London EC1V 2NX. <br>
    </p>
    <img src="${process.env.REACT_APP_LOGO}" alt="logo" width="220" />
    <p style="margin: 0px">Company Reg No: 12667551</p>

  <table
      role="presentation"
      style="width: 100%; text-align: center; vertical-align: middle;"
      cellspacing="0" cellpadding="0"
    >
      <tr>
        <td align="center" valign="middle">
          <img src="https://app.language-interpreters.com/assets/images/iso-9001-2015-badge.png" style="height: 90px;" alt="ISO 9001 2015" hspace="25"/>
          <img src="https://app.language-interpreters.com/assets/images/iso-17100-2015-badge.png" style="height: 90px;" alt="ISO 17100 2015" hspace="25"/>
          <img src="https://app.language-interpreters.com/assets/images/iti-rounded.png" style="height: 90px;" alt="ITI" hspace="25"/>
          <img src="https://app.language-interpreters.com/assets/images/business-partner.png" style="height: 90px;" alt="Business Partner" hspace="25"/>
          <img src="https://app.language-interpreters.com/assets/images/ico.png" style="height: 90px;" alt="Business Partner" hspace="25"/>
        </td>
      </tr>
    </table>

    <div style="margin: 20px 0px; border-top: 1px solid #dbdbdb;">
      <p style="font-size: 8px; font-weight: 700; color: #616d7e; text-align: justify;">
        DISCLAIMER: This email and any files transmitted with it are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you are not the named addressee you should not disseminate, distribute, copy this e-mail or taking any action in reliance on the contents of this information is strictly prohibited. Please notify the sender immediately by e-mail if you have received this e-mail by mistake and delete from your system..
        WARNING: Although Language Interpreters has taken reasonable precautions to ensure no viruses are present in this email, Language Interpreters cannot accept responsibility for any loss or damage arising from the use of this email or attachments.
      </p>
    </div>
  </div>
  `;

  /* **************** Interpreter Booking Confirmation ********************* */
  if (booking?.status !== "Quotation") {
    interpreterContent = `
  <!doctype html>
  <html lang="en">
    <head>
      <!-- Required meta tags -->
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      <link href="//fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap" rel="stylesheet">
      <link href="//fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900&display=swap" rel="stylesheet">
    </head>

    <body>
    <div style="font-family:'Roboto', sans-serif; margin-bottom: 16px;">
    <p style="margin-bottom: 10px;">Dear ${booking?.interpreterName},</p>
    <p style="max-width: 700px; font-size: 14px; text-align: justify; margin-bottom: 10px;">
    We write to confirm the below <strong>${
      booking?.serviceType === "Telephone" ? "Telephonic Interpretation" : ""
    } ${booking?.serviceType === "Video Call" ? "Video Interpretation" : ""} ${
      booking?.serviceType === "Onsite" ? "Onsite Interpretation" : ""
    } ${booking?.serviceType === "Translation" ? "Translation" : ""} ${
      booking?.serviceType === "Transcription" ? "Transcription" : ""
    } </strong> booking with our Customer - ${booking?.companyName}. <br/>
    ${
      booking?.serviceType === "Telephone"
        ? `Your mobile number ${booking?.interpreterMobile} has been forwarded to the client for the purpose of this booking.  Full details are set below.`
        : ""
    }
      ${
        booking?.serviceType === "Video Call"
          ? `Your mobile number ${booking?.interpreterMobile} and your email ID ${booking?.interpreterDetails.email} have been forwarded to the Client for the purpose of this booking. Full details are set below.`
          : ""
      }
      ${
        booking?.serviceType === "Onsite"
          ? `
        <div style="margin: 10px 0px">
          <a href="${process.env.REACT_APP_OTHER_LINK_ENDPOING}/booking/int_conf_view.html?id=${booking.booking_ref}&token=${booking.randToken}&view=${process.env.REACT_APP_MODE}" style="color: #ffffff;background: #151965;width: 200px;padding: 4px 8px;border-radius: 3px;text-decoration: none;" target="_blank">Click here</a> <span style="font-weight: bold;"> for full Booking details.</span>
        </div>
        
        <div style="margin: 10px 0px">
          <a href="${process.env.REACT_APP_OTHER_LINK_ENDPOING}/assets/files/ATTENDANCE%20SHEET.pdf" style="color: #ffffff;background: #e30815;width: 200px;padding: 4px 8px;border-radius: 3px;text-decoration: none;" target="_blank">Click here</a> <span style="font-weight: bold;">to download attendance sheet, please get it signed by the person attending.</span>
        </div>

        Full details are set below.
        `
          : ""
      }
      </div>
      
      ${
        booking?.serviceType === "Translation"
          ? `Please find links to the files for translation purposes.`
          : ""
      }
      ${
        booking?.serviceType === "Transcription"
          ? `Please find links to the files for transcription purposes.`
          : ""
      }
    </p>
    </div>
    
    <div >
        <table style="border:1px solid #dbdbdb; max-width: 600px;">
          <tbody>
            <tr>
              <th
                style="color: #151965d4;text-align:left; padding-left: 14px; font-size: 14px; font-family:'Roboto', sans-serif; font-weight: 500;"
                colspan="3">
                Customer Details
              </th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif; float:right;min-width: 200px;">
                <p style="margin: 0; padding: 2px 0px;"><strong>Date of Request:</strong> ${moment(
                  booking?.createdAt
                ).format("DD-MM-Y")}</p>
                <p style="margin: 0; padding: 2px 0px;"><strong>Booking Reference:</strong> ${
                  booking?.booking_ref
                }</p>
              </td>
            </tr>
            <tr>
              <th style="width: 120px; text-align:left; color: #343a40;padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Customer:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.name
              }</td>
            </tr>
            <tr>
              <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Department:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.department
              }</td>
            </tr>
            <tr>
              <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Customer Email:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.email
              }</td>
            </tr>
            <tr>
              <th style="color: #343a40; text-align:left; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Customer
                Tel:
              </th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif; min-width: 160px;">${
                booking?.mobile + " / " + booking?.phone
              }</td>
            </tr>
            <tr>
              <th style=" color: #343a40; text-align:left; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Company Name:
              </th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.companyName !== undefined
                  ? booking?.companyName
                  : "Private"
              }</td>
            </tr>
            <tr>
              <th style="color: #343a40;text-align:left;  padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Location:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.location
              }</td>
            </tr>
            <!-- job details -->
            <tr>
              <th
                style="color: #151965d4; text-align:left; padding-left: 12px; font-size: 14px; font-family:'Roboto', sans-serif; font-weight: 500; padding-top: 2em;">
                Job Details</th>
            </tr>
            ${
              booking?.companyName !== undefined
                ? `
            <tr>
              <th style="width: 50px; text-align:left; color: #343a40;padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Case Type:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${booking?.caseType}</td>
            </tr>
            `
                : ""
            }
            <tr>
              <th style=" color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;" font-family:'Roboto', sans-serif; font-weight: 400;>Service Required:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.serviceType
              }</td>
            </tr>
            <tr>
              <th style=" color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;" font-family:'Roboto', sans-serif; font-weight: 400;>File Reference:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.fileRef
              }</td>
            </tr>
            <tr>
              <th style="color: #343a40;text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Client Name:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.clientName
              }</td>
            </tr>
            <tr>
              <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Language:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif; ">${
                booking?.language
              }</td>
              <th style="color: #343a40;text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;width: 50px">Dialects:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                booking?.dialect
              }</td>
            </tr>
            <!-- booking details -->
            <tr>
              <th
                style="color: #151965d4; text-align:left; padding-left: 12px; font-size: 14px; font-family:'Roboto', sans-serif; font-weight: 500; padding-top: 2em">
                Booking Details</th>
            </tr>
            <!-- Telephone & Video call -->
            ${
              booking?.serviceType === "Telephone" ||
              booking?.serviceType === "Video Call"
                ? `
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Purpose of the call:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                  booking?.purposeOfCall
                }</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Gender:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif; text-transform: capitalize;">${
                  booking?.gender
                }</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;min-width: 115px"">Date of Attendance:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${moment(
                  booking?.date
                ).format("DD-MM-y")}</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Start Time:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${moment(
                  booking?.startTime
                ).format("HH:mm")}</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">End Time:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${moment(
                  booking?.endTime
                ).format("HH:mm")}</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Duration:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${isDuration}</td>
              </tr>
            `
                : ""
            }
            <!-- Onsite -->
            ${
              booking?.serviceType === "Onsite"
                ? `
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Place of Attendance:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif; text-transform: capitalize;">${
                  booking?.place
                }</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Attendance Location:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                  booking?.placeOfAttendance
                }</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Postcode:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                  booking?.postcode
                }</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Other Reference:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${
                  booking?.otherReference
                }</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Gender:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif; text-transform: capitalize;">${
                  booking?.gender
                }</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Date of Attendance:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${moment(
                  booking?.date
                ).format("DD-MM-Y")}</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Start Time:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${moment(
                  booking?.startTime
                ).format("HH:mm")}</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">End Time:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${moment(
                  booking?.endTime
                ).format("HH:mm")}</td>
              </tr>
              <tr>
                <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
                500;">Duration:</th>
                <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${isDuration}</td>
              </tr>
            `
                : ""
            }
            <!-- Translation & Transcription -->
            ${
              booking?.serviceType === "Translation" ||
              booking?.serviceType === "Transcription"
                ? `
            <tr>
              <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Status:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif; text-transform: capitalize;">${
                booking?.urgency
              }</td>
            </tr>
            <!-- Only for Transcription -->
            ${
              booking?.serviceType === "Transcription"
                ? `
            <tr>
              <th style="color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Type of Format:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${booking?.formatType}</td>
            </tr>
            `
                : ""
            }
            <tr>
              <th style=" color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500;">Date of Return:</th>
              <td style="font-size: 12px; font-family:'Roboto', sans-serif;">${moment(
                booking?.date
              ).format("DD-MM-Y")}</td>
            </tr>
            `
                : ""
            }
            <tr>
              <th style=" color: #343a40; text-align:left; padding-left: 12px; font-size: 12px; font-family:'Roboto', sans-serif; font-weight:
              500; padding-bottom: 2em;">Note/Special:</th>
              <td style="font-size: 12px; max-width: 100px; text-align: justify; padding: 0px 4px; font-family:'Roboto', sans-serif; font-weight:
              400;  padding-bottom: 2.5em;" colspan="3">${booking?.notes}</td>
            </tr>
            <tr>
              <td style="font-size:12px;text-align: center;padding: 10px 14px;font-family:'Roboto',sans-serif;background: blue;font-weight:400;color: #fff;" colspan="4">
                  If you have any queries about this booking / quotation please contact <br />
                  <a href="mailto:bookings@language-interpreters.com" style="color: #fff; font-style: italic;">bookings@language-interpreters.com</a> or 0208 123 5556 by quoting the Booking Reference.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      ${
        booking?.fileList.length !== 0
          ? `<div style="padding: 12px 18px;">
              <h4 style="border-bottom: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 400px; font-weight: bolder;">Documents:</h4>
                <ul style="line-height:120%; list-style-type: none; margin: 0px">
                  ${booking?.fileList?.map(
                    (file) => `<li style="list-style-image: url('${
                      process.env.REACT_APP_OTHER_LINK_ENDPOING
                    }/assets/images/lil-down.png');" >
                    <a style="text-decoration: none; font-family: 'Roboto', sans-serif; font-size: 13px; border: 1px solid #dee2e6; padding: 4px 12px; background-color: #dee2e6; color: #3c4044a8;" href=${
                      process.env.REACT_APP_DOC_LINK_ENDPOINT + file.fileName
                    }>${file.orginalFile}</a>
                    </li>`
                  )}
              </ul>  
            </div>`
          : ""
      }

      ${
        booking?.serviceType === "Translation" ||
        booking?.serviceType === "Transcription"
          ? booking?.formatType.length !== 0 ||
            booking?.formatType !== undefined
            ? `<div style="padding: 12px 18px;">
              <h4 style="border-bottom: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 400px; font-weight: bolder;">Links:</h4>
              <ul style="line-height:120%; list-style-type: none; margin: 0px">
              <a style="text-decoration: none; font-family: 'Roboto', sans-serif; font-size: 13px; color: #3c4044a8;" href=${booking?.urlLink}>${booking?.urlLink}</a>
              </ul>  
            </div>`
            : ""
          : ""
      }

    <div style="padding: 0 14px;">
    <b style="font-family: 'Roboto', sans-serif;"><u>Confidentiality and Non-disclosure:</u></b>
    <p style="font-size: 10.5px;text-align: justify;font-family: 'Roboto', sans-serif;">
    As per our privacy policy that you have been provided you undertake to treat all information concerning clients 
    (including their name, details, and file matters) as confidential during interpreting and/or translating services 
     behalf of <b>Language Interpreters</b>. You will keep such information confidential and will not reveal or use the same 
     except in the proper performance of your services. You will also ensure any material (documents, interviews, discussion notes, 
      photographs, diagrams, reports, or any other information about the case) that you review, generate, translate and/or interpret
       during Interpretation/translation services on behalf of <b>Language Interpreters</b> is kept strictly confidential. You must securely destroy 
       any such material which comes into your possession or that you create during your work for the clients on behalf of <b>Language Interpreters</b>, 
       including your own personal working notes.
    </p>
    </div>
      ${footerSignature}
    </body>

  </html>
    `;
  }

  /* *************** Booking Confrimation / Quotation Mail Content ********************************* */
  if (booking?.status !== "Quotation") {
    // Cusetmer Section
    quotationContent = `
      <p style="color: #37474f !important; margin: 0; padding: 0; font-family: 'Roboto', sans-serif; margin-bottom: 10px;">
        Dear ${booking?.name},
      </p>
      <h6 style="color: #151965 !important; margin: 0; font-size: 12px; line-height: 26px; padding: 0; font-family: 'Roboto', sans-serif; margin-bottom: 10px;">
        ${mailSubject}
      </h6>
      `;

    quotationContent +=
      '<script src="https://cdn.ckeditor.com/4.15.1/standard/ckeditor.js"></script>';
    // Booking Header
    quotationContent += `
      <h6 style="color: #151965 !important; margin: 0; font-size: 12px; line-height: 26px; padding: 0; font-family: 'Roboto', sans-serif;">
        Booking Details:
      </h6>
      <table style="border-collapse: collapse;width: 100%; margin-bottom: 1rem; color: #212529;">
        <tbody style="border-top: 2px solid #dee2e6; border: 1px solid #dee2e6; ">
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; min-width: 100px">
              <strong>Language</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; min-width: 100px">
              ${booking?.language}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; min-width: 100px">
              <strong>Booking Ref</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; min-width: 100px">
              ${booking?.booking_ref}
            </td>
            ${
              sT === "Onsite"
                ? `<td rowspan="9" style="width: 140px">
                    <img src="cid:int@language.com" alt="Interpreter Image" width="140"/>
                  </td>`
                : ""
            }
          </tr>
          ${
            booking?.businessType !== "Private"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Case / Matter Type</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.caseType}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Company</strong>
              </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.companyName}
            </td>
          </tr>`
              : " "
          }
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Department</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="3">
              ${booking?.department}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Location</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.location}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Client Name</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.clientName}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Date of Booking</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${moment(booking?.date).format("DD/MM/yyyy")}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>File Reference</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${booking?.fileRef}
            </td>
          </tr>
          ${
            sT === "Telephone" || sT === "Video Call" || sT === "Onsite"
              ? `
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Start Time</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${moment(booking?.startTime).format("HH:mm")}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Duration</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${isDuration}
            </td>
          </tr>
          `
              : ""
          }
          
          ${
            sT === "Onsite"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Attendance</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.place}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Attendance Address</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.placeOfAttendance}
            </td>
          </tr>`
              : ""
          }
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${
                sT === "Telephone" || sT === "Video Call" || sT === "Onsite"
                  ? "<strong>Interpreter</strong>"
                  : ""
              }
              ${sT === "Translation" ? "<strong>Translator</strong>" : ""}
              ${sT === "Transcription" ? "<strong>Transcriber</strong>" : ""}
              ${sT !== "Onsite" ? "<strong> / ID</strong>" : ""}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; color: #ff0202; font-weight: 700;">
              ${booking?.interpreterDetails?.fullname} ${
      sT !== "Onsite" ? ` / ${booking?.interpreterRef}` : ""
    }
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Qualifications</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;word-break: break-all;  min-width: 100px">
              ${
                typeof booking?.interpreterDetails?.qual_summary !== "undefined"
                  ? booking?.interpreterDetails?.qual_summary
                  : "N/A"
              }
            </td>
          </tr>
          ${
            sT === "Onsite"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>IP ID</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; font-weight: 700;">
              ${booking?.interpreterRef}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>IP Postcode</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.interpreterPostcode}
            </td>
          </tr>`
              : ""
          }
        ${
          sT === "Telephone" || sT === "Video Call"
            ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Interpreter Number</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${
              booking?.interpreterDetails?.mobile +
              " / " +
              booking?.interpreterDetails?.phone
            }
            </td>
            ${
              sT === "Video Call"
                ? `<td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Interpreter Email</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${booking?.interpreterDetails?.email}
            </td>
            `
                : `<td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2"></td>`
            }
          </tr>`
            : ""
        }
        </tbody>
      </table>
      `;

    // Table Section (Booking)
    if (sT === "Telephone" || sT === "Video Call" || sT === "Onsite") {
      quotationContent += `
      <h6 style="color: #151965 !important; margin: 0; font-size: 12px; line-height: 26px; padding: 0; font-family: 'Roboto', sans-serif;">
        Our charges for the booking will be at the following rates:
      </h6>
      <table style="border-collapse: collapse;width: 100%; margin-bottom: 1rem; color: #212529;">
        <thead style="vertical-align: bottom; border-bottom: 2px solid #dee2e6;">
          <tr>
            <th
              style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: left;"
              colspan="2"
            >
              Description
            </th>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;  text-align: right; max-width: 40px">
              ${sT === "Telephone" ? "Charges in £" : ""}
              ${sT === "Video Call" ? "Minimum rate (£)" : ""}
              ${sT === "Onsite" ? "Minimum rate per hour (£)" : ""}
            </th>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right; max-width: 40px">
              Amount (£)
            </th>
          </tr>
        </thead>
        <tbody style="border-top: 2px solid #dee2e6; border: 1px solid #dee2e6;">
          ${
            sT === "Telephone"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Cost per minute</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.quotation?.costPerMin}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"></td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Number of minutes</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.durationMin}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalDurationAmount.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Video Call"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Duration</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.durationMin}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.costPerHour.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalDurationAmount.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Onsite"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Attendance 1</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.attendanceOne}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.attendanceOneMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalAttendanceOneAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Attendance 2</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.attendanceTwo}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.attendanceTwoMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalAttendanceTwoAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Travel Inward charges</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.estimatedTravelIn}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation.travelInMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalTravelInAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Travel Outward charges</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.estimatedTravelOut}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.travelOutMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalTravelOutAmount.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Telephone" || sT === "Video Call" || sT === "Onsite"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Waiting time</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.estimatedWaiting}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${
                sT === "Onsite"
                  ? booking?.quotation?.waitingMinRate.toFixed(2)
                  : ""
              }
              ${
                sT === "Telephone"
                  ? booking?.quotation?.totalWaitingAmount.toFixed(2)
                  : ""
              }
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation.totalWaitingAmount.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Onsite"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Parking charges</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              As per evidence
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.parkingFee.toFixed(2)}
            </td>
          </tr>  
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Mileage</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.estimatedMileage}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.mileageMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalMileageAmount?.toFixed(2)}
            </td>
          </tr>  
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Travel fares</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              As per evidence
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.estimatedFares.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Telephone"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Minimum charges & Duration costs</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.quotation.durationTeleAndVideoInMins}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalTeleAndVideoAmount.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
            </td>
          </tr>`
              : ""
          }
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>Discounts</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.discounts.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>Sub Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.subTotalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2">
              <strong>${serviceLabel}</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              @${booking?.serviceChargePercent}%
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalPercentage.toFixed(2)}
            </td>
          </tr>
           <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="3">
              <strong>Invoice Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${totalInvoiceAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="3">
              <strong>Advance Payment</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.advancePayment.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="3">
              <strong>Amount Due</strong>
            </td>  
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${totalDueAmount.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      `;
    } else if (sT === "Translation" || sT === "Transcription") {
      quotationContent += `
      <h6 style="color: #151965 !important; margin: 0; font-size: 12px; line-height: 26px; padding: 0; font-family: 'Roboto', sans-serif;">
        Our charges for the booking will be at the following rates:
      </h6>
      <table style="border-collapse: collapse;width: 100%; margin-bottom: 1rem; color: #212529;">
        <thead style="vertical-align: bottom; border-bottom: 2px solid #dee2e6;">
          <tr>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; max-width: 100px; text-align: left;" colspan=${
              sT !== "Tranlsation" ? 3 : 0
            }>
              ${sT === "Translation" ? "Status" : ""}
              ${sT === "Transcription" ? "Description" : ""}
            </th>
            
            ${
              sT === "Translation"
                ? `<th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 100px;" >
               Date of Return
            </th>
            
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" >
              ${sT === "Translation" ? "Rate per word" : ""}
            </th>`
                : ""
            }
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 100px; text-align: center;">
              ${sT === "Translation" ? "Number of pages" : ""}
              ${sT === "Transcription" ? "Hours" : ""}
            </th>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 100px; text-align: right;">
              ${sT === "Translation" ? "Word count" : ""}
              ${sT === "Transcription" ? "Minimum rate (£)" : ""}
            </th>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 100px; text-align: right;">
              Amount (£)
            </th>
          </tr>
        </thead>
        <tbody style="border-top: 2px solid #dee2e6; border: 1px solid #dee2e6; ">
        ${
          sT === "Translation"
            ? `
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: left;" colspan="3">
              ${booking?.urgency === "urgent" ? "Urgent" : "Non Urgent"}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: center;">
              ${moment(booking?.date).format("DD/MM/yyyy")}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: center;" >
              ${booking?.quotation?.costPerWord}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: center;" >
              ${booking?.pageCount}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;" >
              ${booking?.wordCount}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${parseFloat(
                booking?.quotation?.transTotalDurationAmount
              ).toFixed(2)}
            </td>
          </tr>`
            : ""
        }
        ${
          sT === "Transcription"
            ? `
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            Status
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: left;" colspan=${
              sT !== "Translation" ? 2 : ""
            }>
              ${booking?.urgency === "urgent" ? "Urgent" : "Non Urgent"}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
             ${parseFloat(
               booking?.quotation?.minimumUrgencyAndNonUrgencyCharge
             ).toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="3">
              Transcription cost per min (charged over 60mins)
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: center;" colspan=${
              sT !== "Translation" ? 1 : 0
            }>
              ${booking?.durationMin}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.costPerMin.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${parseFloat(
                booking?.quotation?.totalTransDurationAmount
              ).toFixed(2)}
            </td>
          </tr>
        `
            : ""
        }
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
                  colspan=${sT !== "Translation" ? 4 : 6}>
                  ${
                    sT !== "Translation"
                      ? "STANDARD TRANSLATION & PROOFREADING"
                      : ""
                  }
                  </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right">
              <strong>Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
            colspan=${sT !== "Translation" ? 4 : 6}></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>${sT === "Translation" ? "Volume" : ""} Discounts</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation.discounts.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
            colspan=${sT !== "Translation" ? 4 : 6}></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>Sub Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.subTotalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan=${
              sT !== "Translation" ? 4 : 6
            }>
              <strong>${serviceLabel}</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              @${booking?.serviceChargePercent}%
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalPercentage.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
            colspan=${sT !== "Translation" ? 5 : 7}>
              <strong>Invoice Total</strong>
            </td>  
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${totalInvoiceAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
            colspan=${sT !== "Translation" ? 5 : 7}>
              <strong>Advance Payment</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.advancePayment.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
            colspan=${sT !== "Translation" ? 5 : 7}>
              <strong>Amount Due</strong>
            </td>  
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${totalDueAmount.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      `;
    }
  } else {
    quotationContent = `
      <p style="color: #37474f !important; margin: 0; padding: 0; font-family: 'Roboto', sans-serif; margin-bottom: 10px;">
        Dear ${booking?.name},
      </p>
      <h6 style="color: #151965 !important; margin: 0; font-size: 12px; line-height: 26px; padding: 0; font-family: 'Roboto', sans-serif; margin-bottom: 10px;">
        ${mailSubject}
      </h6>
      `;

    // Table Section (Quotation)
    if (sT === "Telephone" || sT === "Video Call" || sT === "Onsite") {
      quotationContent += `
      <h6 style="color: #151965 !important; margin: 0; font-size: 12px; line-height: 26px; padding: 0; font-family: 'Roboto', sans-serif;">
        Our charges for the booking will be at the following rates:
      </h6>
      <table style="border-collapse: collapse;width: 100%; margin-bottom: 1rem; color: #212529;">
        <thead style="vertical-align: bottom; border-bottom: 2px solid #dee2e6;">
          <tr>
            <th
              style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: left;"
              colspan="2"
            >
              Description
            </th>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;  text-align: right; max-width: 40px">
              ${sT === "Telephone" ? "Charges in £" : ""}
              ${sT === "Video Call" ? "Minimum rate (£)" : ""}
              ${sT === "Onsite" ? "Minimum rate per hour (£)" : ""}
            </th>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right; max-width: 40px">
              Amount (£)
            </th>
          </tr>
        </thead>
        <tbody style="border-top: 2px solid #dee2e6; border: 1px solid #dee2e6;">
          ${
            sT === "Telephone"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Cost per minute</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.quotation?.costPerMin}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"></td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Number of minutes</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.durationMin}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalDurationAmount.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Video Call"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Duration</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.durationMin}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.costPerHour.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalDurationAmount.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Onsite"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Attendance 1</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.attendanceOne}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.attendanceOneMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalAttendanceOneAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Attendance 2</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.attendanceTwo}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.attendanceTwoMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalAttendanceTwoAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Travel Inward charges</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.estimatedTravelIn}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation.travelInMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalTravelInAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Travel Outward charges</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.estimatedTravelOut}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.travelOutMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalTravelOutAmount.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Telephone" || sT === "Video Call" || sT === "Onsite"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Waiting time</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.estimatedWaiting}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${
                sT === "Onsite"
                  ? booking?.quotation?.waitingMinRate.toFixed(2)
                  : ""
              }
              ${
                sT === "Telephone"
                  ? booking?.quotation?.totalWaitingAmount.toFixed(2)
                  : ""
              }
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation.totalWaitingAmount.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Onsite"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Parking charges</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              As per evidence
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.parkingFee.toFixed(2)}
            </td>
          </tr>  
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Mileage</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.estimatedMileage}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.mileageMinRate.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalMileageAmount?.toFixed(2)}
            </td>
          </tr>  
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Travel fares</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              As per evidence
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.estimatedFares.toFixed(2)}
            </td>
          </tr>`
              : ""
          }
          ${
            sT === "Telephone"
              ? `<tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Minimum charges & Duration costs</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.quotation.durationTeleAndVideoInMins}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalTeleAndVideoAmount.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
            </td>
          </tr>`
              : ""
          }
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>Discounts</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.discounts.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2"></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>Sub Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.subTotalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="2">
              <strong>${serviceLabel}</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              @${booking?.serviceChargePercent}%
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalPercentage.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="3">
              <strong>Grand Total</strong>
            </td>  
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.grandTotal.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      `;
    } else if (sT === "Translation" || sT === "Transcription") {
      quotationContent += `
      <h6 style="color: #151965 !important; margin: 0; font-size: 12px; line-height: 26px; padding: 0; font-family: 'Roboto', sans-serif;">
        Our charges for the booking will be at the following rates:
      </h6>
      <table style="border-collapse: collapse;width: 100%; margin-bottom: 1rem; color: #212529;">
        <thead style="vertical-align: bottom; border-bottom: 2px solid #dee2e6;">
          <tr>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; max-width: 100px; text-align: left;" colspan=${
              sT !== "Tranlsation" ? 3 : 0
            }>
              ${sT === "Translation" ? "Status" : ""}
              ${sT === "Transcription" ? "Description" : ""}
            </th>
            
            ${
              sT === "Translation"
                ? `<th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 100px;" >
               Date of Return
            </th>
            
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" >
              ${sT === "Translation" ? "Rate per word" : ""}
            </th>`
                : ""
            }
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 100px; text-align: center;">
              ${sT === "Translation" ? "Number of pages" : ""}
              ${sT === "Transcription" ? "Hours" : ""}
            </th>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 100px; text-align: right;">
              ${sT === "Translation" ? "Word count" : ""}
              ${sT === "Transcription" ? "Minimum rate (£)" : ""}
            </th>
            <th style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; width: 100px; text-align: right;">
              Amount (£)
            </th>
          </tr>
        </thead>
        <tbody style="border-top: 2px solid #dee2e6; border: 1px solid #dee2e6; ">
        ${
          sT === "Translation"
            ? `
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: left;" colspan="3">
              ${booking?.urgency === "urgent" ? "Urgent" : "Non Urgent"}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: center;">
              ${moment(booking?.date).format("DD/MM/yyyy")}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: center;" >
              ${booking?.quotation?.costPerWord}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: center;" >
              ${booking?.pageCount}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;" >
              ${booking?.wordCount}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${parseFloat(
                booking?.quotation?.transTotalDurationAmount
              ).toFixed(2)}
            </td>
          </tr>`
            : ""
        }
        ${
          sT === "Transcription"
            ? `
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            Status
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: left;" colspan=${
              sT !== "Translation" ? 2 : ""
            }>
              ${booking?.urgency === "urgent" ? "Urgent" : "Non Urgent"}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
             ${parseFloat(
               booking?.quotation?.minimumUrgencyAndNonUrgencyCharge
             ).toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="3">
              Transcription cost per min (charged over 60mins)
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: center;" colspan=${
              sT !== "Translation" ? 1 : 0
            }>
              ${booking?.durationMin}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.costPerMin.toFixed(2)}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${parseFloat(
                booking?.quotation?.totalTransDurationAmount
              ).toFixed(2)}
            </td>
          </tr>
        `
            : ""
        }
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
                  colspan=${sT !== "Translation" ? 4 : 6}>
                  ${
                    sT === "Translation"
                      ? "STANDARD TRANSLATION & PROOFREADING"
                      : ""
                  }
                  </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right">
              <strong>Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
            colspan=${sT !== "Translation" ? 4 : 6}></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>${sT === "Translation" ? "Volume" : ""} Discounts</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation.discounts.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
            colspan=${sT !== "Translation" ? 4 : 6}></td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              <strong>Sub Total</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.subTotalAmount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan=${
              sT !== "Translation" ? 4 : 6
            }>
              <strong>${serviceLabel}</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              @${booking?.serviceChargePercent}%
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.totalPercentage.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;"
            colspan=${sT !== "Translation" ? 5 : 7}>
              <strong>Grand Total</strong>
            </td>  
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; text-align: right;">
              ${booking?.quotation?.grandTotal.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      `;
    }

    // Quotation Header
    quotationContent += `
    <h6 style="color: #151965 !important; margin: 0; font-size: 12px; line-height: 26px; padding: 0; font-family: 'Roboto', sans-serif;">
      Quotation Details:
    </h6>
    <table style="border-collapse: collapse;width: 100%; margin-bottom: 1rem; color: #212529;">
      <tbody style="border-top: 2px solid #dee2e6; border: 1px solid #dee2e6; ">
        <tr>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; min-width: 100px">
            <strong>Language</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; min-width: 100px">
            ${booking?.language}
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; min-width: 100px">
            <strong>Booking Ref</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; min-width: 100px">
            ${booking?.booking_ref}
          </td>
        </tr>
        ${
          booking?.businessType !== "Private"
            ? `<tr>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            <strong>Case / Matter Type</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${booking?.caseType}
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            <strong>Company</strong>
            </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${booking?.companyName}
          </td>
        </tr>`
            : " "
        }
        <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>Department</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;" colspan="3">
              ${booking?.department}
            </td>
          </tr>
        <tr>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            <strong>Location</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${booking?.location}
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            <strong>Client Name</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${booking?.clientName}
          </td>
        </tr>
        <tr>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            <strong>Date of Attendance</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${moment(booking?.date).format("DD/MM/yyyy")}
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            <strong>File Reference</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
          ${booking?.fileRef}
          </td>
        </tr>

        ${
          sT === "Telephone" || sT === "Video Call" || sT === "Onsite"
            ? `        
        <tr>
        <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
          <strong>Start Time</strong>
        </td>
        <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
        ${moment(booking?.startTime).format("HH:mm")}
        </td>
        <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
          <strong>Duration</strong>
        </td>
        <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
        ${isDuration}
        </td>
      </tr>
              `
            : ""
        }

        
        ${
          sT === "Onsite"
            ? `
        <tr>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            <strong>Attendance</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${booking?.place}
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            <strong>Attendance Address</strong>
          </td>
          <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
            ${booking?.placeOfAttendance}
          </td>
        </tr>
        
        `
            : ""
        }
      ${
        sT === "Telephone" || sT === "Video Call" || sT === "Onsite"
          ? `
      <tr>
        <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
          <strong>Interpreter</strong>
        </td>
        <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; color: #ff0202; font-weight: 700;">
          ${booking?.interpreterDetails?.fullname}
        </td>
        <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
          <strong>Qualifications</strong>
        </td>
        <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;word-break: break-all;  min-width: 100px">
          ${
            typeof booking?.interpreterDetails?.qual_summary !== "undefined"
              ? booking?.interpreterDetails?.qual_summary
              : "N/A"
          }
        </td>
      </tr>
      `
          : ""
      }
      ${
        sT === "Onsite"
          ? `
      <tr>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>IP ID</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px; font-weight: 700;">
              ${booking?.interpreterRef}
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              <strong>IP Postcode</strong>
            </td>
            <td style="padding: 0.3rem; vertical-align: top; border: 1px solid #dee2e6; font-family: 'Roboto', sans-serif; font-size: 12px;">
              ${booking?.interpreterPostcode}
            </td>
      </tr>
      `
          : ""
      }
        </tbody>
      </table>
    `;

    quotationContent += `
    <center>
      <div
        style=" display:-webkit-flex; display:-ms-flexbox; display: flex; justify-content: flex-start; align-items: center; margin: 1rem 0rem">
        <span style="font-weight: bolder; margin-right: 12px;">Click here to</span>
        <a href="${process.env.REACT_APP_OTHER_LINK_ENDPOING}/approve/acceptQuotation.html?br=${booking.booking_ref}&token=${booking.randToken}&view=${process.env.REACT_APP_MODE}"
          target="_blank"
          style="width: 180px; text-align: center; text-decoration: none;
          padding: 0.5rem 0.2rem; background-color: #f54642; border: none; color: #ffffff; cursor: pointer; border-radius: 4px; font-weight: bolder; font-size: 14px;">
         Accept Quotation
        </a>
      </div>
    </center>
    `;
  }

  mailContainer = `
  <div style="box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 0.15); transition: 0.3s; min-width: 800px; max-width: 880px; width: auto;">
    <div style="padding: 5px 16px">
      ${quotationContent}

    </div>
    <p style="margin: 12px;"><strong>To view details of this ${
      booking?.status !== "quotation" ? "booking" : "quotation"
    } please</strong>
      <a href="${
        process.env.REACT_APP_OTHER_LINK_ENDPOING
      }/booking/view.html?id=${booking?.booking_ref}&token=${
    booking?.randToken
  }&view=${
    process.env.REACT_APP_MODE
  }" target="_blank"  style="background: lightskyblue; padding: 2px 8px; margin-left: 4px; border-radius: 3px; text-decoration: none">click here</a>
    </p>
    <div style="padding: 5px 16px;">
      ${term !== undefined ? term[0].text : null}
    </div>
    ${footerSignature}
  </div>
  `;

  /* *************** Local View ******************************************* */
  if (!loading) {
    invoiceContent = (
      <>
        <DialogTitle id="alert-dialog-title">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            {/* <Grid item>{booking !== undefined ? (!booking.isBooked ? 'Send ' + booking.bookingType : 'Booking Confirmation') : ''} </Grid> */}
            <Grid item>
              {booking?.status !== "Quotation"
                ? "Send Booking Confirmation"
                : "Send Quotation"}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {isHide ? (
            <>
              {booking.isBooked && (
                <Paper className={classes.paper} variant="outlined">
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: "5px" }}
                    color="inherit"
                  >
                    Dear {booking.name}.
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: "5px" }}
                    color="inherit"
                  >
                    {/* {booking.serviceType} {booking.bookingType} confirmation. */}
                    {/* {booking.serviceType} ${booking.status === 'Booking' ? `${booking.status} confirmation` : booking.status}. */}
                    {mailSubject}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ fontWeight: "600", paddingBottom: "5px" }}
                    color="primary"
                  >
                    Booking Details:
                  </Typography>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>Language</strong>
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            align="left"
                          >
                            {booking.language}
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            align="left"
                          >
                            <strong>Booking Ref</strong>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableTopBorder}
                          >
                            {booking.booking_ref}
                          </TableCell>

                          {sT === "Onsite" && (
                            <TableCell
                              align="left"
                              className={classes.tableTopBorder}
                              rowSpan={9}
                            >
                              {!iload ? (
                                <img
                                  src={profileImage}
                                  alt="Interpreter"
                                  width="180"
                                />
                              ) : (
                                "loading..."
                              )}
                            </TableCell>
                          )}
                        </TableRow>

                        {booking?.businessType !== "Private" && (
                          <TableRow>
                            <TableCell className={classes.tableTopBorder}>
                              <strong>Case/Matter Type</strong>
                            </TableCell>
                            <TableCell
                              className={classes.tableTopBorder}
                              align="left"
                            >
                              {booking.caseType}
                            </TableCell>
                            <TableCell
                              className={classes.tableTopBorder}
                              align="left"
                            >
                              <strong>Company</strong>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableTopBorder}
                            >
                              {booking.companyName}
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>Department</strong>
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            align="left"
                          >
                            {booking.department}
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                          ></TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                          ></TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>Location</strong>
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            align="left"
                          >
                            {booking.location}
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            align="left"
                          >
                            <strong>Client Name</strong>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableTopBorder}
                          >
                            {booking.clientName}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            className={classes.tableTopBorder}
                            style={{ minWidth: 120 }}
                          >
                            <strong>
                              {sT === "Translation" || sT === "Transcription"
                                ? "Date of Return"
                                : "Date of Booking"}
                            </strong>
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            align="left"
                          >
                            {moment(booking.date).format("DD/MM/yyyy")}
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            align="left"
                          >
                            <strong>File Reference</strong>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableTopBorder}
                          >
                            {booking.fileRef}
                          </TableCell>
                        </TableRow>

                        {sT === "Telephone" ||
                        sT === "Video Call" ||
                        sT === "Onsite" ? (
                          <TableRow>
                            <TableCell className={classes.tableTopBorder}>
                              <strong>Start Time</strong>
                            </TableCell>
                            <TableCell
                              className={classes.tableTopBorder}
                              align="left"
                            >
                              {moment(booking.startTime).format("HH:mm")}
                            </TableCell>
                            <TableCell
                              className={classes.tableTopBorder}
                              align="left"
                            >
                              <strong>Duration</strong>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableTopBorder}
                            >
                              {isDuration}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}

                        {sT === "Onsite" && (
                          <>
                            <TableRow>
                              <TableCell className={classes.tableTopBorder}>
                                <strong>Attendance Location</strong>
                              </TableCell>
                              <TableCell
                                className={classes.tableTopBorder}
                                align="left"
                              >
                                {booking.place}
                              </TableCell>
                              <TableCell
                                className={classes.tableTopBorder}
                                align="left"
                              >
                                <strong>Attendance Address</strong>
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableTopBorder}
                              >
                                {booking.placeOfAttendance}
                              </TableCell>
                            </TableRow>
                          </>
                        )}

                        <TableRow>
                          <TableCell
                            className={classes.tableTopBorder}
                            style={{ minWidth: 150 }}
                          >
                            <strong>
                              {(sT === "Telephone" ||
                                sT === "Video Call" ||
                                sT === "Onsite") &&
                                "Interpreter"}{" "}
                              {sT !== "Onsite" ? " / ID" : ""}
                              {sT === "Translation" && "Translator"}
                              {sT === "Transcription" && "Transcriber"}
                            </strong>
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            align="left"
                            style={{ minWidth: 160 }}
                          >
                            {booking.interpreterDetails.fullname}{" "}
                            {sT !== "Onsite"
                              ? " / " + booking?.interpreterRef
                              : null}
                          </TableCell>
                          <TableCell
                            className={classes.tableTopBorder}
                            style={{ minWidth: 150 }}
                            align="left"
                          >
                            <strong>Qualifications</strong>
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableTopBorder}
                          >
                            {booking.interpreterDetails.qual_summary}
                          </TableCell>
                        </TableRow>
                        {sT === "Onsite" && (
                          <TableRow>
                            <TableCell className={classes.tableTopBorder}>
                              <strong>IP ID</strong>
                            </TableCell>
                            <TableCell className={classes.tableTopBorder}>
                              {booking?.interpreterRef}
                            </TableCell>
                            <TableCell className={classes.tableTopBorder}>
                              <strong>IP Postcode</strong>
                            </TableCell>
                            <TableCell className={classes.tableTopBorder}>
                              {booking?.interpreterPostcode}
                            </TableCell>
                          </TableRow>
                        )}
                        {(sT === "Telephone" || sT === "Video Call") && (
                          <TableRow>
                            <TableCell className={classes.tableTopBorder}>
                              <strong>Interpreter Number</strong>
                            </TableCell>
                            <TableCell
                              className={classes.tableTopBorder}
                              align="left"
                            >
                              {booking.interpreterDetails.mobile +
                                " / " +
                                booking.interpreterDetails.phone}
                            </TableCell>
                            <TableCell
                              className={classes.tableTopBorder}
                              align="left"
                            >
                              <strong>
                                {sT === "Video Call" && "Interpreter Email"}
                              </strong>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableTopBorder}
                            >
                              {sT === "Video Call" &&
                                booking.interpreterDetails.email}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}

              <Paper className={classes.paper} variant="outlined">
                {!booking.isBooked && (
                  <>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ paddingBottom: "5px" }}
                      color="inherit"
                    >
                      Dear {booking.name}.
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ paddingBottom: "5px" }}
                      color="inherit"
                    >
                      {/* {booking.serviceType} {booking.bookingType}. */}
                      {/* Ref: {booking.serviceType} {booking.status === 'Booking' ? `${booking.status} confirmation` : booking.status}. */}
                      {mailSubject}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ fontWeight: "600", paddingBottom: "5px" }}
                      color="primary"
                    >
                      Our charges for the booking will be at the following
                      rates:
                    </Typography>
                  </>
                )}
                <TableContainer>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    {(sT === "Telephone" ||
                      sT === "Video Call" ||
                      sT === "Onsite") && (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                            >
                              Description
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                              align="right"
                            >
                              {(sT === "Video Call" || sT === "Onsite") &&
                                "Hours"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                              align="right"
                            >
                              {sT === "Telephone" && "Charges in £"}
                              {sT === "Video Call" && "Minimum rate (£)"}
                              {sT === "Onsite" && "Minimum rate per hour (£)"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                              align="right"
                            >
                              Amount (£)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Telephone */}
                          {sT === "Telephone" && (
                            <>
                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Cost per minute
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colTwo}
                                  align="right"
                                >
                                  {booking.quotation.costPerMin}
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  align="right"
                                  style={styles.colThree}
                                ></TableCell>
                                <TableCell
                                  align="right"
                                  style={styles.colFour}
                                ></TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Number of minutes
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colTwo}
                                  align="right"
                                >
                                  {booking.durationMin}
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colThree}
                                  align="right"
                                ></TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.quotation.totalDurationAmount.toFixed(
                                    2
                                  )}
                                </TableCell>
                              </TableRow>
                            </>
                          )}

                          {/* Video Call */}
                          {sT === "Video Call" && (
                            <TableRow>
                              <TableCell
                                className={classes.tableRightBorder}
                                style={styles.colOne}
                                component="th"
                                scope="row"
                              >
                                Duration
                              </TableCell>
                              <TableCell
                                className={classes.tableRightBorder}
                                style={styles.colTwo}
                                align="right"
                              >
                                {booking.durationMin}
                              </TableCell>
                              <TableCell
                                className={classes.tableRightBorder}
                                style={styles.colThree}
                                align="right"
                              ></TableCell>
                              <TableCell style={styles.colFour} align="right">
                                {booking.quotation !== undefined
                                  ? booking.quotation.totalDurationAmount.toFixed(
                                      2
                                    )
                                  : null}
                              </TableCell>
                            </TableRow>
                          )}

                          {/* Onsite */}
                          {sT === "Onsite" && (
                            <>
                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Attendance 1
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colTwo}
                                  align="right"
                                >
                                  {booking.attendanceOne}
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colThree}
                                  align="right"
                                >
                                  {booking.quotation.attendanceOneMinRate.toFixed(
                                    2
                                  )}
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.quotation.totalAttendanceOneAmount.toFixed(
                                    2
                                  )}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Attendance 2
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colTwo}
                                  align="right"
                                >
                                  {booking.attendanceTwo}
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colThree}
                                  align="right"
                                >
                                  {booking.quotation.attendanceTwoMinRate.toFixed(
                                    2
                                  )}
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.quotation.totalAttendanceTwoAmount.toFixed(
                                    2
                                  )}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Travel inward charges
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colTwo}
                                  align="right"
                                >
                                  {booking.estimatedTravelIn}
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colThree}
                                  align="right"
                                >
                                  {booking.quotation.travelInMinRate.toFixed(2)}
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.quotation.totalTravelInAmount.toFixed(
                                    2
                                  )}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Travel outward charges
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colTwo}
                                  align="right"
                                >
                                  {booking.estimatedTravelOut}
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colThree}
                                  align="right"
                                >
                                  {booking.quotation.travelOutMinRate.toFixed(
                                    2
                                  )}
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.quotation.totalTravelOutAmount.toFixed(
                                    2
                                  )}
                                </TableCell>
                              </TableRow>
                            </>
                          )}

                          {/* Telephone, Video Call and Onsite */}
                          {(sT === "Telephone" ||
                            sT === "Video Call" ||
                            sT === "Onsite") && (
                            <TableRow>
                              <TableCell
                                style={styles.colOne}
                                className={classes.tableRightBorder}
                                component="th"
                                scope="row"
                              >
                                Waiting time
                              </TableCell>
                              <TableCell
                                style={styles.colTwo}
                                className={classes.tableRightBorder}
                                align="right"
                              >
                                {booking.estimatedWaiting}
                              </TableCell>
                              <TableCell
                                style={styles.colThree}
                                className={classes.tableRightBorder}
                                align="right"
                              >
                                {sT === "Onsite" &&
                                  booking.quotation.waitingMinRate.toFixed(2)}
                                {sT === "Telephone" &&
                                  booking.quotation.totalWaitingAmount.toFixed(
                                    2
                                  )}
                              </TableCell>
                              <TableCell style={styles.colFour} align="right">
                                {booking.quotation !== undefined
                                  ? booking.quotation.totalWaitingAmount.toFixed(
                                      2
                                    )
                                  : null}
                              </TableCell>
                            </TableRow>
                          )}

                          {/* Onsite */}
                          {sT === "Onsite" && (
                            <>
                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Parking charges
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colTwo}
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colThree}
                                  align="right"
                                >
                                  As per evidence
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.parkingFee.toFixed(2)}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Mileage
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colTwo}
                                  align="right"
                                >
                                  {booking?.estimatedMileage}
                                </TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colThree}
                                  align="right"
                                >
                                  {booking.quotation.mileageMinRate}
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {/* {booking.estimatedMileage.toFixed(2)} */}
                                  {booking.quotation.totalMileageAmount?.toFixed(
                                    2
                                  )}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                >
                                  Travel fares
                                </TableCell>
                                <TableCell
                                  style={styles.colTwo}
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  className={classes.tableRightBorder}
                                  style={styles.colThree}
                                  align="right"
                                >
                                  As per evidence
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.estimatedFares.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            </>
                          )}

                          {/* Telephone */}
                          {sT === "Telephone" && (
                            <TableRow>
                              <TableCell
                                style={styles.colOne}
                                className={classes.tableRightBorder}
                                component="th"
                                scope="row"
                              >
                                Minimum charges & Duration costs
                              </TableCell>
                              <TableCell
                                style={styles.colTwo}
                                className={classes.tableRightBorder}
                                align="right"
                              >
                                {booking.quotation.durationTeleAndVideoInMins}
                              </TableCell>
                              <TableCell
                                style={styles.colThree}
                                className={classes.tableRightBorder}
                                align="right"
                              >
                                {booking.quotation.totalTeleAndVideoAmount.toFixed(
                                  2
                                )}
                              </TableCell>
                              <TableCell
                                style={styles.colFour}
                                align="right"
                              ></TableCell>
                            </TableRow>
                          )}

                          {/* Calculation */}
                          {/* Telephone  */}
                          {sT === "Telephone" && (
                            <>
                              <TableRow>
                                <TableCell
                                  style={styles.colOne}
                                  className={classes.tableRightBorder}
                                  component="th"
                                  scope="row"
                                ></TableCell>
                                <TableCell
                                  style={styles.colTwo}
                                  className={classes.tableRightBorder}
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  style={styles.colThree}
                                  className={classes.tableRightBorder}
                                  align="right"
                                >
                                  <strong>Total</strong>
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.quotation.totalAmount.toFixed(2)}
                                </TableCell>
                              </TableRow>

                              {/* Telephone */}
                              <TableRow>
                                <TableCell
                                  style={styles.colOne}
                                  component="th"
                                  scope="row"
                                ></TableCell>
                                <TableCell
                                  style={styles.colTwo}
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  style={styles.colThree}
                                  className={classes.tableRightBorder}
                                  align="right"
                                >
                                  Discounts
                                </TableCell>
                                <TableCell style={styles.colFour} align="right">
                                  {booking.quotation.discounts.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                          <TableRow>
                            <TableCell style={styles.colOne}></TableCell>
                            <TableCell style={styles.colTwo}></TableCell>
                            <TableCell
                              style={styles.colThree}
                              className={classes.tableRightBorder}
                              align="right"
                            >
                              <strong>Sub Total</strong>
                            </TableCell>
                            <TableCell style={styles.colFour} align="right">
                              {booking.quotation !== undefined
                                ? booking.quotation.subTotalAmount.toFixed(2)
                                : null}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={styles.colOne}
                              component="th"
                              scope="row"
                            >
                              {serviceLabel}
                            </TableCell>
                            <TableCell
                              style={styles.colTwo}
                              className={classes.tableRightBorder}
                              align="right"
                            ></TableCell>
                            <TableCell
                              style={styles.colThree}
                              className={classes.tableRightBorder}
                              align="right"
                            >
                              @{booking?.serviceChargePercent}%
                            </TableCell>
                            <TableCell style={styles.colFour} align="right">
                              {booking.quotation !== undefined
                                ? booking.quotation.totalPercentage.toFixed(2)
                                : null}
                            </TableCell>
                          </TableRow>

                          {booking.status !== "Quotation" && (
                            <TableRow>
                              <TableCell
                                style={styles.colOne}
                                component="th"
                                scope="row"
                              >
                                <strong>Invoice Total</strong>
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell
                                style={styles.colThree}
                                className={classes.tableRightBorder}
                                align="right"
                              ></TableCell>
                              <TableCell style={styles.colFour} align="right">
                                {booking.invTotal === undefined
                                  ? totalInvoiceAmount.toFixed(2)
                                  : booking.invTotal}
                              </TableCell>
                            </TableRow>
                          )}

                          {booking.status !== "Quotation" && (
                            <TableRow>
                              <TableCell
                                style={styles.colOne}
                                component="th"
                                scope="row"
                              >
                                Advance Payment
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell
                                style={styles.colThree}
                                className={classes.tableRightBorder}
                                align="right"
                              ></TableCell>
                              <TableCell style={styles.colFour} align="right">
                                {booking.advancePayment.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}

                          <TableRow>
                            <TableCell
                              style={styles.colOne}
                              component="th"
                              scope="row"
                            >
                              <strong>
                                {booking?.status !== "Quotation"
                                  ? "Amount Due"
                                  : "Grand Total"}
                              </strong>
                            </TableCell>
                            <TableCell
                              style={styles.colTwo}
                              align="right"
                            ></TableCell>
                            <TableCell
                              style={styles.colThree}
                              className={classes.tableRightBorder}
                              align="right"
                            ></TableCell>
                            <TableCell style={styles.colFour} align="right">
                              {totalDueAmount.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </>
                    )}

                    {/* Translation */}
                    {(sT === "Translation" || sT === "Transcription") && (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                            >
                              {sT === "Translation" && "Status"}
                              {sT === "Transcription" && "Description"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                              align="right"
                            >
                              {sT === "Translation" && "Rate per word"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                              align="right"
                            >
                              {sT === "Translation" && "Number of pages"}
                              {sT === "Transcription" && "Hours"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                              align="right"
                            >
                              {sT === "Translation" && "Word count"}
                              {sT === "Transcription" && "Minimum rate (£)"}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                width: "200px",
                              }}
                              align="right"
                            >
                              Amount (£)
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Translation */}
                          {sT === "Translation" && (
                            <TableRow>
                              <TableCell
                                style={styles.colTranOne}
                                className={classes.tableRightBorder}
                                component="th"
                                scope="row"
                              >
                                {booking.urgency === "urgent"
                                  ? "Urgent"
                                  : "Non Urgent"}
                              </TableCell>
                              <TableCell
                                style={styles.colTranTwo}
                                className={classes.tableRightBorder}
                                align="right"
                              >
                                {booking.quotation.costPerWord}
                              </TableCell>
                              <TableCell
                                style={styles.colTranThree}
                                className={classes.tableRightBorder}
                                align="right"
                              >
                                {booking.pageCount}
                              </TableCell>
                              <TableCell
                                style={styles.colTranFour}
                                className={classes.tableRightBorder}
                                align="right"
                              >
                                {booking.wordCount}
                              </TableCell>
                              <TableCell
                                style={styles.colTranFive}
                                align="right"
                              >
                                {booking.quotation?.transTotalDurationAmount.toFixed(
                                  2
                                )}
                              </TableCell>
                            </TableRow>
                          )}

                          {/* Transcription */}
                          {sT === "Transcription" && (
                            <>
                              <TableRow>
                                <TableCell
                                  style={styles.colTranOne}
                                  className={classes.tableRightBorder}
                                  component="th"
                                  scope="row"
                                >
                                  Status
                                </TableCell>
                                <TableCell
                                  style={styles.colTranTwo}
                                  className={classes.tableRightBorder}
                                  align="right"
                                >
                                  {booking.urgency === "urgent"
                                    ? "Urgent"
                                    : "Non Urgent"}
                                </TableCell>
                                <TableCell
                                  style={styles.colTranThree}
                                  className={classes.tableRightBorder}
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  style={styles.colTranFour}
                                  className={classes.tableRightBorder}
                                  align="right"
                                >
                                  {parseFloat(
                                    booking.quotation
                                      ?.minimumUrgencyAndNonUrgencyCharge
                                  ).toFixed(2)}
                                </TableCell>
                                <TableCell
                                  style={styles.colTranFive}
                                  align="right"
                                ></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={styles.colTranOne}
                                  className={classes.tableRightBorder}
                                  component="th"
                                  scope="row"
                                >
                                  Transcription cost per min (charged over
                                  60mins)
                                </TableCell>
                                <TableCell
                                  style={styles.colTranTwo}
                                  className={classes.tableRightBorder}
                                  align="right"
                                ></TableCell>
                                <TableCell
                                  style={styles.colTranThree}
                                  className={classes.tableRightBorder}
                                  align="right"
                                >
                                  {booking.durationMin}
                                </TableCell>
                                <TableCell
                                  style={styles.colTranFour}
                                  className={classes.tableRightBorder}
                                  align="right"
                                >
                                  {booking.quotation?.costPerMin}
                                </TableCell>
                                <TableCell
                                  style={styles.colTranFive}
                                  align="right"
                                >
                                  {booking.quotation?.totalTransDurationAmount.toFixed(
                                    2
                                  )}
                                </TableCell>
                              </TableRow>
                            </>
                          )}

                          <TableRow>
                            <TableCell
                              style={styles.colOne}
                              component="th"
                              scope="row"
                            ></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              style={styles.colThree}
                              className={classes.tableRightBorder}
                              align="right"
                            >
                              <strong>Total</strong>
                            </TableCell>
                            <TableCell style={styles.colFour} align="right">
                              {booking.quotation?.totalAmount.toFixed(2)}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={styles.colOne}
                              component="th"
                              scope="row"
                            ></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              style={styles.colThree}
                              className={classes.tableRightBorder}
                              align="right"
                            >
                              Discounts
                            </TableCell>
                            <TableCell style={styles.colFour} align="right">
                              {booking.quotation?.discounts.toFixed(2)}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={styles.colOne}
                              component="th"
                              scope="row"
                            ></TableCell>

                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              style={styles.colThree}
                              className={classes.tableRightBorder}
                              align="right"
                            >
                              <strong>Sub Total</strong>
                            </TableCell>
                            <TableCell style={styles.colFour} align="right">
                              {booking.quotation?.subTotalAmount.toFixed(2)}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              style={styles.colOne}
                              component="th"
                              scope="row"
                            >
                              {serviceLabel}
                            </TableCell>

                            <TableCell></TableCell>
                            <TableCell
                              className={classes.tableRightBorder}
                            ></TableCell>
                            <TableCell
                              style={styles.colThree}
                              className={classes.tableRightBorder}
                              align="right"
                            >
                              @{booking?.serviceChargePercent}%
                            </TableCell>
                            <TableCell style={styles.colFour} align="right">
                              {booking.quotation?.totalPercentage.toFixed(2)}
                            </TableCell>
                          </TableRow>

                          {booking.status !== "Quotation" && (
                            <TableRow>
                              <TableCell
                                style={styles.colOne}
                                component="th"
                                scope="row"
                              >
                                <strong>Invoice Total</strong>
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell
                                style={styles.colThree}
                                className={classes.tableRightBorder}
                                align="right"
                              ></TableCell>
                              <TableCell style={styles.colFour} align="right">
                                {booking.invTotal === undefined
                                  ? totalInvoiceAmount.toFixed(2)
                                  : booking.invTotal}
                              </TableCell>
                            </TableRow>
                          )}

                          {booking.status !== "Quotation" && (
                            <TableRow>
                              <TableCell
                                style={styles.colOne}
                                component="th"
                                scope="row"
                              >
                                Advance Payment
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell
                                style={styles.colThree}
                                className={classes.tableRightBorder}
                                align="right"
                              ></TableCell>
                              <TableCell style={styles.colFour} align="right">
                                {booking.advancePayment.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}

                          <TableRow>
                            <TableCell
                              style={styles.colOne}
                              component="th"
                              scope="row"
                            >
                              <strong>
                                {booking?.status !== "Quotation"
                                  ? "Amount Due"
                                  : "Grand Total"}
                              </strong>
                            </TableCell>

                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell
                              style={styles.colThree}
                              className={classes.tableRightBorder}
                              align="right"
                            ></TableCell>
                            <TableCell style={styles.colFour} align="right">
                              {totalDueAmount.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
              </Paper>

              {!booking.isBooked && (
                <Paper className={classes.paper} variant="outlined">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ fontWeight: "600", paddingBottom: "5px" }}
                    color="primary"
                  >
                    Quotation Details:
                  </Typography>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>Language</strong>
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            {booking.language}
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>Booking Ref</strong>
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            {booking.booking_ref}
                          </TableCell>
                        </TableRow>

                        {booking?.businessType !== "Private" && (
                          <TableRow>
                            <TableCell className={classes.tableTopBorder}>
                              <strong>Case/Matter Type</strong>
                            </TableCell>
                            <TableCell className={classes.tableTopBorder}>
                              {booking.caseType}
                            </TableCell>
                            <TableCell className={classes.tableTopBorder}>
                              <strong>Company</strong>
                            </TableCell>
                            <TableCell className={classes.tableTopBorder}>
                              {booking.companyName}
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>Location</strong>
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            {booking.location}
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>Client Name</strong>
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            {booking.clientName}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>
                              {sT === "Translation" || sT === "Transcription"
                                ? "Date of Return"
                                : "Date of Booking"}
                            </strong>
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            {moment(booking.date).format("DD/MM/yyyy")}
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            <strong>File Reference</strong>
                          </TableCell>
                          <TableCell className={classes.tableTopBorder}>
                            {booking.fileRef}
                          </TableCell>
                        </TableRow>

                        {sT === "Telephone" ||
                        sT === "Video Call" ||
                        sT === "Onsite" ? (
                          <TableRow>
                            <TableCell className={classes.tableTopBorder}>
                              <strong>Start Time</strong>
                            </TableCell>
                            <TableCell
                              className={classes.tableTopBorder}
                              align="left"
                            >
                              {moment(booking.startTime).format("HH:mm")}
                            </TableCell>
                            <TableCell
                              className={classes.tableTopBorder}
                              align="left"
                            >
                              <strong>Duration</strong>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableTopBorder}
                            >
                              {isDuration}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}

                        {sT === "Onsite" && (
                          <>
                            <TableRow>
                              <TableCell className={classes.tableTopBorder}>
                                <strong>Attendance Location</strong>
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                {booking.place}
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                <strong>Attendance Address</strong>
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                {booking.placeOfAttendance}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.tableTopBorder}>
                                <strong>Interpreter</strong>
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                {booking?.interpreterName}
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                <strong>Qualifications</strong>
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                {booking?.interpreterDetails?.qual_summary}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={classes.tableTopBorder}>
                                <strong>IP ID</strong>
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                {booking?.interpreterRef}
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                <strong>IP Postcode</strong>
                              </TableCell>
                              <TableCell className={classes.tableTopBorder}>
                                {booking?.interpreterPostcode}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}

              <Paper className={classes.paper} variant="outlined">
                <div dangerouslySetInnerHTML={{ __html: term[0]?.text }} />
                {/* <div dangerouslySetInnerHTML={{ __html: mailContainer }} /> */}
              </Paper>
            </>
          ) : (
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ width: "500px" }}
            >
              Do you want to send the mail?
            </DialogContentText>
          )}{" "}
        </DialogContent>
        <Button size="small" onClick={() => setIsHide(!isHide)}>
          {isHide ? "Hide" : "Preview"}
        </Button>
        <DialogActions>
          <Button onClick={closeHandler} color="primary" size="small">
            Close
          </Button>
          <Button
            onClick={confirmHandler}
            color="primary"
            variant="contained"
            size="small"
            disabled={isSendBtn}
          >
            {isSendBtn ? "Sending..." : "Send"}
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth="md"
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <div dangerouslySetInnerHTML={{ __html: mailContainer }} /> */}
        {invoiceContent}
      </Dialog>
    </React.Fragment>
  );
});
